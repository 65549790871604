import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { sessionService } from '../../services/SessionService';
import DatePicker from "react-datepicker";
import ImageUploader from "react-images-upload";

interface NewPaymentProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class NewPayment extends React.Component<NewPaymentProps> {

    @observable private payment: any = {
        type: "",
        amount: "",
        invoice_id: "",
        date: new Date(),
        folio: "",
        card_id: "",
        paid_by: "",
        notes: "",
        picture_id: "",
    };

    @observable private date = new Date();
    @observable private remaining = "";
    @observable private redirect = false;
    @observable private antena = false;
    @observable private canContinue = false;
    @observable private cards = [];
    @observable private types = [
        { id: "Transferencia", name: "Transferencia" },
        { id: "Deposito", name: "Deposito" },
        { id: "Oficina", name: "Oficina" },
        { id: "Tecnico", name: "Tecnico" }
    ];
    @observable private validationErrors: string[] = [
        "type",
        "amount"
    ];
    @observable private isLoaded: boolean = false;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        this.payment.invoice_id = id;
        http.get(`/api/payment/left/${id}`).then((response) => {
            this.remaining = response.data;
            if (Number(this.remaining) < 0) {
                this.types.push({ id: "Antena", name: "Antena" })
                this.antena = true;
                this.payment.amount = this.remaining;
                this.payment.type = "Antena";
                this.validationErrors = [];
            }
        }).then(() => {
            http.get(`/api/card/read`).then((response) => {
                this.cards = response.data.response;
                this.isLoaded = true;
            })
        });
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.payment[name] = value;
    }

    private onDrop2 = (pictureFiles: any) => {
        if (pictureFiles.length > 0) {
            let index = pictureFiles.length - 1;
            let formData = new FormData();
            //generate an unique id for image using the current date so it won't be repeated
            this.payment.picture_id = new Date().getTime().toString();
            formData.append('id', this.payment.picture_id);
            formData.append('file', pictureFiles[index], pictureFiles[index].name);
            http.post('/api/payment/uploadImage', formData)
                .then((res) => {
                    this.canContinue = true;
                    toast.success("Imagen subida con exito");
                }).catch(() => {
                    toast.error("Error subiendo imagen");
                });
        }
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        this.payment.created_by = sessionService.getUserInfo().id;

        this.payment.amount = this.payment.amount.replace("$", '').replace(/,/g, '');

        this.payment.date = `${this.date.getFullYear()}-${("0" + (this.date.getMonth() + 1)).slice(-2)}-${("0" + this.date.getDate()).slice(-2)}`;

        if (this.canContinue === false) {
            toast.error("Debes subir una imagen");
            return;
        }

        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        http.post(`/api/payment/create`, JSON.stringify(this.payment))
            .then(() => {
                toast.success(`Pago agregado`);
                this.redirect = true;
            }).catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((val) => {
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }

    setStartDate = (date: any) => {
        this.date = date;
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to="/pagos" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Registrar un Pago</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../pagos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                {this.payment.invoice_id &&
                                    <InputForm
                                        name="invoice_id"
                                        label="Recibo #"
                                        value={this.payment.invoice_id}
                                        setValidation={this.setValidation}
                                        required={false}
                                        onChange={this.handleChange}
                                        type="text"
                                        disabled={true}
                                    />
                                }
                                <InputForm
                                    name="remaining"
                                    label="Cantidad pendiente de pagar"
                                    value={this.remaining}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    prefix="$"
                                    decimal={2}
                                    thousandSeparator={true}
                                    disabled={true}
                                />
                                <InputForm
                                    name="amount"
                                    label="Cantidad recibida"
                                    value={this.payment.amount}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    prefix="$"
                                    decimal={2}
                                    thousandSeparator={true}
                                    disabled={Number(this.remaining) < 0}
                                />
                                <InputForm
                                    name="type"
                                    label="Tipo de pago"
                                    value={this.payment.type}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    options={this.types}
                                    disabled={Number(this.remaining) < 0}
                                    type="select"
                                />
                                {this.payment.type !== "Oficina" && this.payment.type !== "Antena" && this.payment.type !== "Tecnico" &&
                                    <Fragment>
                                        <InputForm
                                            name="folio"
                                            label="Folio"
                                            value={this.payment.folio}
                                            setValidation={this.setValidation}
                                            required={true}
                                            onChange={this.handleChange}
                                            type="text"
                                            disabled={false}
                                        />
                                        <InputForm
                                            name="card_id"
                                            label="Tarjeta"
                                            value={this.payment.card_id}
                                            setValidation={this.setValidation}
                                            required={true}
                                            onChange={this.handleChange}
                                            options={this.cards}
                                            type="select"
                                        />
                                    </Fragment>
                                }
                                {this.payment.type === "Tecnico" &&
                                    <InputForm
                                        name="paid_by"
                                        label="Nombre del Tecnico"
                                        value={this.payment.paid_by}
                                        setValidation={this.setValidation}
                                        required={true}
                                        onChange={this.handleChange}
                                        type="text"
                                        disabled={false}
                                    />
                                }
                                <div className="row">
                                    <label className="col-sm-2 col-form-label">Fecha de pago</label>
                                    <div className="col-sm-10">
                                        <div className="form-group bmd-form-group is-filled">
                                            <DatePicker selected={this.date} onChange={date => this.setStartDate(date)} />
                                        </div>
                                    </div>
                                </div>
                                <InputForm
                                    name="notes"
                                    label="Notas (Max: 255 caracteres)"
                                    value={this.payment.notes}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="textarea"
                                    maxLength={255}
                                />
                                <ImageUploader
                                    withIcon={true}
                                    buttonText="Subir Imagenes"
                                    onChange={this.onDrop2}
                                    imgExtension={[".jpg", "jpeg"]}
                                    maxFileSize={5242880}
                                    withPreview={true}
                                    singleImage={true}
                                    label="Sube imagenes de la instalación"
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Agregar Pago</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}