import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { sessionService } from '../../services/SessionService';
import swal from 'sweetalert';

interface EditContractProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class EditContract extends React.Component<EditContractProps> {

    @observable private contract: any;
    @observable private isLoaded = false;
    @observable private redirect = false;
    @observable private validationErrors: string[] = [];
    @observable private clients: any = [];
    @observable private packages: any = [];
    @observable private cutoffs: any = [];
    @observable private sectorials: any = [];
    @observable private availableIps: any = [];
    @observable private usedIps: any = [];
    @observable private payment_types = [
        { id: "COBRADOR", name: "Cobrador" },
        { id: "DEPOSITO", name: "Deposito" },
        { id: "TRANSFERENCIA", name: "Transferencia" }
    ];
    @observable private house_types = [
        { id: "Propia", name: "Propia" },
        { id: "Renta", name: "Renta" }
    ]
    @observable private statuses = [
        { id: "Pendiente Instalacion", name: "Pendiente Instalacion" },
        { id: "Activo", name: "Activo" },
        { id: "Pendiente Desconexion", name: "Pendiente Desconexion" },
        { id: "Desconectado", name: "Desconectado" },
        { id: "Pendiente Conexion", name: "Pendiente Conexion" },
        { id: "Cerrado", name: "Cerrado" },
        { id: "Pendiente Retiro", name: "Pendiente Retiro" },
        { id: "Empleado", name: "Empleado" }
    ]

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        http.get(`/api/contract/read/${id}`).then((response) => {
            this.contract = response.data;
            this.contract.mac = this.contract.mac ? this.contract.mac : "";
        })
            .then(() => {
                return http.get(`/api/client/readAll`).then((response) => {
                    this.clients = response.data.response;
                })
            })
            .then(() => {
                return http.get(`/api/package/read`).then((response) => {
                    this.packages = response.data.response;
                })
            })
            .then(() => {
                return http.get(`/api/cutoff/read`).then((response) => {
                    this.cutoffs = response.data.response;
                })
            })
            .then(() => {
                return http.get(`/api/sectorial/available`).then((response) => {
                    this.sectorials = response.data.response;
                });
            })
            .then(() => {
                const request = JSON.stringify({
                    id: this.contract.sectorial_id
                });
                return http.post(`/api/sectorial/ips`, request).then((response) => {
                    this.usedIps = response.data.response;
                    if (this.contract.sectorial_id !== "") {
                        this.generateAvailableIps();
                    }
                    this.isLoaded = true;
                })
            })
            .catch(() => {
                toast.error("Error cargando contrato");
            });
    }

    private addNotes = () => {
        swal({
            title: "Notas de Edicion",
            text: "Escribe la razon de la edicion:",
            content: {
                element: "input"
            }
        })
            .then(test => {
                this.contract.notes_edition = test;
            }).then(this.onSubmit);
    }

    private handleSectorialChange = () => {
        if (this.contract.sectorial_id === "") {
            this.availableIps = [];
            this.contract.ip = "";
            return;
        }
        const request = JSON.stringify({
            id: this.contract.sectorial_id
        });
        http.post(`/api/sectorial/ips`, request).then((response) => {
            this.usedIps = response.data.response;
            this.generateAvailableIps();
        })
    }

    private generateAvailableIps = () => {
        const sectorial = this.sectorials.find((x: any) => {
            return x.id === this.contract.sectorial_id
        });

        this.availableIps = [];
        if (this.contract.ip && this.contract.ip !== "") { //Si ya tiene ip debemos incluir la actual
            this.availableIps.push({
                name: this.contract.ip,
                id: this.contract.ip
            });
        }
        if (sectorial) {
            for (let i = Number(sectorial.range_start); i <= Number(sectorial.range_end); i++) {
                if (this.usedIps.indexOf(`${sectorial.ip_start}.${i}`) === -1) {
                    this.availableIps.push({
                        name: `${sectorial.ip_start}.${i}`,
                        id: `${sectorial.ip_start}.${i}`
                    });
                }
            }
            this.contract.ip = this.availableIps[0].id;
            this.setValidation(false, "ip");
        }
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.contract[name] = value;
        if (name === "sectorial_id") {
            this.handleSectorialChange();
        }
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        // eslint-disable-next-line
        if (this.contract.reference_phone) {
            this.contract.reference_phone = this.contract.reference_phone.replace(/-/g, '');
        }
        if (this.contract.sectorial_id === null || this.contract.sectorial_id === "") {
            delete this.contract.sectorial_id;
            delete this.contract.ip;
        }
        if (this.contract.reference_phone === null || this.contract.reference_phone === "") {
            delete this.contract.reference_phone;
        }

        this.contract.updated_by = sessionService.getUserInfo().id;
        http.post(`/api/contract/update`, JSON.stringify(this.contract))
            .then(() => {
                toast.success(`Contrato actualizado`);
                this.redirect = true;
            }).catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((val) => {
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }

        if (this.redirect) {
            return <Redirect to="/contratos" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Editar contrato #{this.contract.id} - {this.contract.address_street} {this.contract.address_colonia} {this.contract.address_interior} {this.contract.address_exterior}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../contratos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <button onClick={this.addNotes} type="submit" className="btn btn-primary pull-right">Guardar cambios</button>
                                    </div>
                                </div>
                                <InputForm
                                    name="client_id"
                                    label="Cliente"
                                    value={this.contract.client_id}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.clients}
                                    disabled={true}
                                />
                                <InputForm
                                    name="phone"
                                    label="Teléfono"
                                    value={this.contract.client_phone}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="text"
                                    disabled={true}
                                />
                                <InputForm
                                    name="other_phones"
                                    label="Otros Telefonos"
                                    value={this.contract.other_phones}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="package_id"
                                    label="Paquete"
                                    value={this.contract.package_id}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.packages}
                                    disabled={false}
                                />
                                <InputForm
                                    name="balance"
                                    label="Saldo a favor"
                                    value={this.contract.balance}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    decimal={2}
                                    thousandSeparator={true}
                                    disabled={false}
                                />
                                <InputForm
                                    name="due_balance"
                                    label="Saldo deudor"
                                    value={this.contract.due_balance}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    decimal={2}
                                    thousandSeparator={true}
                                    disabled={false}
                                />
                                <InputForm
                                    name="sectorial_id"
                                    label="Sectorial"
                                    value={this.contract.sectorial_id}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    options={this.sectorials}
                                    type="select"
                                />
                                <InputForm
                                    name="ip"
                                    label="Elegir otra IP"
                                    value={this.contract.ip}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    options={this.availableIps}
                                    type="select"
                                />
                                <InputForm
                                    name="mac"
                                    label="Mac Adress"
                                    value={this.contract.mac}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="reference"
                                    label="Referencia"
                                    value={this.contract.reference}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="reference_phone"
                                    label="Teléfono de Referencia"
                                    value={this.contract.reference_phone}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="number"
                                    format={"###-###-####"}
                                />
                                <InputForm
                                    name="reference_relationship"
                                    label="Relación de Referencia"
                                    value={this.contract.reference_relationship}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="address_street"
                                    label="Dirección del Contrato"
                                    value={this.contract.address_street}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="address_colonia"
                                    label="Colonia"
                                    value={this.contract.address_colonia}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="address_interior"
                                    label="Número Interior"
                                    value={this.contract.address_interior}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="text" 
                                />
                                <InputForm
                                    name="address_exterior"
                                    label="Número Exterior"
                                    value={this.contract.address_exterior}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="address_zipcode"
                                    label="Codigo Postal"
                                    value={this.contract.address_zipcode}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    format={"#####"}
                                />
                                <InputForm
                                    name="address_reference"
                                    label="Referencias del lugar"
                                    value={this.contract.address_reference}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="payment_type"
                                    label="Forma de Pago"
                                    value={this.contract.payment_type}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.payment_types}
                                />
                                <InputForm
                                    name="house_type"
                                    label="Tipo de Casa"
                                    value={this.contract.house_type}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.house_types}
                                />
                                <InputForm
                                    name="collection_time"
                                    label="Horario de cobro preferido"
                                    value={this.contract.collection_time}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="cutoff_date_id"
                                    label="Fecha de Corte"
                                    value={this.contract.cutoff_date_id}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.cutoffs.map((value: any) => {
                                        return { id: value.id, name: "Dia " + value.date + " - (" + value.contracts + " contratos)" }
                                    })}
                                />
                                <InputForm
                                    name="notes_creation"
                                    label="Notas (Max: 255 caracteres)"
                                    value={this.contract.notes_creation}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="textarea"
                                    maxLength={255}
                                />
                                <InputForm
                                    name="status"
                                    label="Estado del contrato"
                                    value={this.contract.status}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    options={this.statuses}
                                    type="select"
                                />
                                <button onClick={this.addNotes} type="submit" className="btn btn-primary pull-right">Guardar cambios</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}